<template>
  <v-select
    v-model="entidade"
    name="entidade"
    :items="entidades"
    placeholder=""
    :background-color="backgroundColor"
    dense
    class="mb-0 pb-0"
  />
</template>

<script>
export default {
  name: 'MapeamentoArquivo',
  props: {
    arquivo: {
      type: Object,
      required: true
    },
    entidades: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      entidade: null
    };
  },
  watch: {
    entidade() {
      this.emiteEventoMapeamentoAtualizado();
    }
  },
  computed: {
    mapeado() {
      return this.entidade !== null;
    },
    backgroundColor() {
      return this.mapeado ? null : 'red lighten-4';
    }
  },
  mounted() {
    this.setEntidade();
  },
  methods: {
    emiteEventoMapeamentoAtualizado() {
      this.$emit('mapeamento:atualizado', {
        arquivoId: this.arquivo.id,
        entidade: this.entidade,
        mapeado: this.mapeado
      });
    },
    setEntidade() {
      const [arquivo] = this.arquivo.arquivo.split('.');
      const nomeArquivo = arquivo.toUpperCase();

      // Essa entidade necessita de tratamento especial, ou será mapeada erroneamente como EQTRM
      if (nomeArquivo === 'EQTRMT') {
        this.entidade = 'EQTRMT';
        return true;
      }

      // Com base no início do nome do arquivo, caso seja semelhante ao da entidade, seleciona a entidade
      const entidade = this.entidades.find((entidade) => {
        let nomeEntidade = entidade.toUpperCase();
        let nomesSemelhantes = nomeArquivo.startsWith(nomeEntidade);
        return nomesSemelhantes;
      });

      if (entidade) {
        this.entidade = entidade;
      }

      this.emiteEventoMapeamentoAtualizado();
    }
  }
};
</script>
